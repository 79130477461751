@import '../../../../variables';

.technologyCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  &_imgFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 244px;
    height: 244px;
    background: #ffffff;
    padding: 45px;

    @include vp-1439 {
      width: 194px;
      height: 194px;
      padding: 30px;
    }

    @include vp-767 {
      width: 164px;
      height: 164px;
      padding: 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &_name {
    margin: 0;
    margin-top: 10px;
    color: #282d32;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    @include vp-767 {
      font-size: 14px;
    }
  }
}
