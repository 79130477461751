.prv__pol {
  max-width: 1310px;
  margin: 80px auto 20px;
}
.prv__title {
  color: #282d32;
  font-weight: 700;
  font-size: 16px;
}
.h2 {
  font-weight: 700;
}
.prv__edit {
  color: #282d32;
  font-weight: 100;
  font-size: 16px;
}
.prv-sub__title {
  color: #282d32;
  font-weight: 500;
  font-size: 16px;
}
.prv__text {
  color: #4c5157;
  font-weight: 100;
  font-size: 14px;
}
.prv__link {
  color: #4c5157;
}
.prv-sub__text {
  margin-left: 14px;
}
.prv__email {
  text-decoration: none;
  color: #4c5157;
}
.prv__content {
  font-size: 16px;
  color: #282d32;
  font-weight: 500 !important;
}
.tou {
  max-width: 1310px;
  margin: 80px auto 20px;
}
.tou__title {
  color: #282d32;
  font-weight: 600;
  font-size: 16px;
}
.tou-sub__title {
  color: #282d32;
  font-weight: 500;
  font-size: 16px;
}
.tou__text {
  color: #4c5157;
  font-weight: 100;
  font-size: 14px;
}
.tou__site {
  text-decoration: none;
  color: #4c5157;
  text-decoration: underline;
}
.tou__list {
  color: #4c5157;
  font-weight: 100;
  font-size: 14px;
  margin-left: 40px;
}
.tou__mail {
  text-decoration: none;
  color: #4c5157;
}
.cok {
  max-width: 1310px;
  margin: 80px auto 20px;
}
.cok__title {
  color: #282d32;
  font-weight: 600;
  font-size: 16px;
}
.cok-sub__title {
  color: #282d32;
  font-weight: 500;
  font-size: 16px;
}
.cok__text {
  color: #4c5157;
  font-weight: 100;
  font-size: 14px;
}
.cok__site {
  text-decoration: none;
  color: #4c5157;
}
.cok__list {
  color: #4c5157;
  font-weight: 100;
  font-size: 14px;
  margin-left: 40px;
}
@media (max-width: 1400px) {
  .prv__pol {
    max-width: 90%;
  }
  .tou {
    max-width: 90%;
  }
  .cok {
    max-width: 90%;
  }
}
@media (max-width: 500px) {
  .prv__title {
    font-size: 16px;
  }
  .prv-sub__title {
    font-size: 16px;
  }
  .prv__text {
    font-size: 14px;
  }
  .tou__title {
    font-size: 16px;
  }
  .tou-sub__title {
    font-size: 16px;
  }
  .tou__text {
    font-size: 14px;
  }
  .tou__list {
    font-size: 14px;
    margin-left: 5px;
  }
  .cok__title {
    font-size: 16px;
  }
  .cok-sub__title {
    font-size: 16px;
  }
  .cok__text {
    font-size: 14px;
  }
  .cok__list {
    font-size: 14px;
    margin-left: 5px;
  }
}
