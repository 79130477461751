@import '../../../../../variables.scss';

.providerSlider {
  width: 100%;
  color: #000000;
  margin-top: -10px;

  @include vp-1023 {
    display: none;
  }

  :global(.swiper-slide) {
    width: fit-content;
  }

  &_card {
    display: inline-flex;
    padding: 10px;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    background: #fafafa;
  }

  &_name {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: capitalize;
  }
}
