@import '../variables';

.appContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.22) 7.62%,
      rgba(58, 235, 255, 0.1) 25.5%,
      rgba(255, 255, 255, 0.1) 70.92%
    ),
    #fafafa;

  &_grey {
    background: #fafafa;
  }

  &_casino {
    background: #ffffff;
  }
}
