@import '../../../../variables';

.sliderCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  min-height: 500px;
  margin: 0 auto;
  overflow: hidden;

  &_title {
    margin: 0;
    color: #282d32;
    text-align: center;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;
    max-width: 1225px;
    width: 100%;
    word-wrap: break-word;

    @include vp-1439 {
      padding: 0 30px;
    }

    @include vp-767 {
      font-size: 40px;
      padding: 0 13px;
    }

    @include vp-374 {
      font-size: 32px;
    }

    b {
      color: #2a95bc;
    }
  }

  &_description {
    margin: 0;
    margin-top: 40px;
    color: #4c5157;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width: 1040px;
    width: 100%;
    transition: $trans-default;

    @include vp-1439 {
      padding: 0 73px;
    }

    @include vp-767 {
      margin-top: 17px;
      padding: 0 16px;
      font-size: 20px;
    }

    &_fade {
      animation: fade 1.2s ease;

      @keyframes fade {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
}
