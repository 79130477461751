@import '../../../../variables.scss';

.projectCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 600px;
  background: #ffffff;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  @media (max-width: 1350px) {
    min-height: 600px;
  }

  @include vp-767 {
    min-height: 550px;
  }

  @include vp-500 {
    min-height: 350px;
  }

  &_bg {
    position: relative;
  }

  &_imgBg {
    width: 640px;
    height: 366px;

    @media (max-width: 1350px) {
      width: 100%;
      height: auto;
    }
  }

  &_imgLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 85%;
    object-fit: contain;

    @include vp-767 {
      max-width: 45%;
    }
  }

  &_long,
  &_longReverse {
    flex-direction: row;
    grid-column: 1 / -1;
    min-height: 366px;

    @media (max-width: 1350px) {
      flex-direction: column;
    }

    .projectCard_imgBg {
      width: 421px;
      height: 366px;

      @media (max-width: 1350px) {
        width: 100%;
        height: auto;
      }

      @media (max-width: 1023px) {
        width: 620px;
        height: 366px;
      }

      @include vp-767 {
        width: 100%;
        height: auto;
      }
    }

    .projectCard_imgLogo {
      max-width: 50%;
    }
  }

  &_longReverse {
    flex-direction: row-reverse;

    @media (max-width: 1350px) {
      flex-direction: column;
    }
  }

  &_textContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 20px;

    @include vp-1023 {
      gap: 16px;
    }

    @include vp-500 {
      gap: 10px;
    }
  }

  &_label {
    margin: 0;
    color: #0078b4;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @include vp-767 {
      font-size: 13px;
    }
  }

  &_title {
    margin-top: 0px;
    margin-bottom: 0;
    color: #282d32;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    a {
      text-decoration: none;
      color: inherit;
    }

    @include vp-767 {
      font-size: 24px;
    }
  }

  &_description {
    margin-top: 0px;
    color: #4c5157;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: justify;

    @media (max-width: 1350px) {
      padding-right: 100px;
    }

    @include vp-1023 {
      padding-right: 5px;
    }

    @include vp-767 {
      font-size: 20px;
    }
  }

  &_link {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
    padding-bottom: 11px;
    padding-right: 9px;
    margin-top: auto;
    color: #3eafd4;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-decoration: none;
    transition: $trans-default;

    @media (max-width: 1350px) {
      margin-top: 20px;
    }

    @include vp-1023 {
      margin-top: 36px;
    }

    @include vp-767 {
      padding-right: 9px;
      margin-top: 20px;
    }

    &:hover {
      color: #2a95bc;
    }
  }
}
