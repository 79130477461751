@import '../../../../variables';

.technologyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;

  &_title {
    align-self: flex-start;
    margin-top: 0;
    margin-bottom: 20px;
    color: #2a95bc;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include vp-767 {
      font-size: 24px;
    }

    @include vp-374 {
      align-self: center;
    }
  }

  &_list {
    display: grid;
    grid-template-columns: repeat(5, 244px);
    gap: 20px;
    padding: 0;
    margin: 0;
    list-style: none;

    @include vp-1439 {
      grid-template-columns: repeat(3, 194px);
    }

    @include vp-767 {
      grid-template-columns: repeat(2, 164px);
    }

    @include vp-374 {
      grid-template-columns: repeat(2, 134px);
    }
  }
}
