@import '../../variables.scss';

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  max-width: 1300px;
  padding-bottom: 50px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1350px) {
    padding: 0 68px;
    padding-bottom: 50px;
  }

  @include vp-1023 {
    gap: 20px;
    padding-bottom: 10px;
  }

  @include vp-767 {
    gap: 15px;
    padding: 0 16px;
    padding-bottom: 20px;
  }

  &_title {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #282d32;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    @include vp-767 {
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 40px;
    }
  }

  &_description {
    margin: 0;
    color: #4c5157;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 0 105px;

    @include vp-1023 {
      padding: 0 15px;
    }

    @include vp-767 {
      font-size: 20px;
    }

    @include vp-500 {
      padding: 0;
    }
  }

  &_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;

    @media (max-width: 1350px) {
      grid-template-columns: 850px;
      justify-content: center;
    }

    @media (max-width: 1023px) {
      grid-template-columns: 620px;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }

    @include vp-500 {
      margin-top: 0;
    }
  }

  &_item {
    width: 100%;
    height: 766px;
    border: 1px solid red;

    &_long {
      grid-column: 1 / -1;
      height: 366px;
    }
  }
}
