@mixin vp-1919 {
  @media (max-width: 1919px) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: 1439px) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin vp-500 {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin vp-374 {
  @media (max-width: 374px) {
    @content;
  }
}

$tf-default: ease;
$trans-default: 0.3s $tf-default;
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);
$trans-600: 0.6s $tf-default;
