@import './fonts/fonts';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  min-height: 100vh;
}

.app {
  box-sizing: border-box;
  font-family: 'Petrov Sans-Trial';
  height: 100%;
  min-height: 100vh;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    font-family: 'Petrov Sans-Trial';
  }

  main {
    flex: 1 0 auto;
  }
}

.accept-cookies {
  position: fixed;
  z-index: 900;
  background-color: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  transition:
    opacity 0.35s,
    visibility 0.35s;
  transition-delay: 0s;
  transition-timing-function: ease;
  font-size: 18px;
  color: #404040;
  font-weight: 400;
  left: 1%;
  width: 98%;
  border-radius: 20px;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 6.25rem;
  padding: 1.25rem 2rem;
}

.accept-cookies > * + * {
  margin-left: 2.5rem;
}

.show {
  visibility: visible;
}

.accept-cookies-text {
  display: contents;
}

.accept-cookies img {
  width: 65px;
  height: 65px;
  margin-bottom: auto;
}

.accept-cookies p {
  padding-left: 2.5rem;
  font-size: 12px;
  line-height: 18px;
}

.accept-cookies .close-btn {
  width: 35px;
  height: 35px;
  right: 20px;
  position: relative;
  cursor: pointer;
}
.accept-cookies a {
  color: #404040;
  text-decoration: underline;
  cursor: pointer;
}

.accept-cookies a.hover,
.accept-cookies__text a:hover {
  text-decoration: underline;
}

.accept-cookies.hide {
  opacity: 0;
  display: none;
}

.accept-cookies.hidden {
  display: none;
}

div .cookies-settings-btn {
  margin-top: 25px;
}

.cookies-settings-canvas {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: -90px;
  z-index: 1000;
  width: 100%;
  height: 120%;
  backdrop-filter: blur(3px);
}

.cookies-settings-list #coockiesS1 .cookies-settings-link {
  cursor: auto;
}

.cookies-settings-list #coockiesS1 .cookies-settings-arrow {
  cursor: auto;
}

.cookies-settings {
  display: table;
  margin: auto;
  margin-top: auto;
  position: sticky;
  bottom: 400px;
  z-index: 9999;
  background-color: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 24px;
  color: #4c5157;
  font-weight: 400;
  left: 1%;
  border-radius: 18px;
  padding: 1.25rem 2rem;
  width: 618px;
  height: 300px;
  margin-top: 100px;
}

.cookies-settings-container {
  position: relative;
  right: 28px;
  height: 600px;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.cookies-settings-header {
  text-align: left;
  font-weight: 500;
  font-size: 32px;
  line-height: 38.4px;
  color: #282d32;
  margin-left: 45px;
  margin-top: 20px;
  width: 493px;
  position: relative;
}

.cookies-settings-container p {
  font-size: 18px;
  max-width: 420px;
  margin-left: 91px;
  margin-top: 16px;
  top: 3px;
  position: relative;
  color: #282d32;
}

.cookies-settings-link {
  font-weight: 500;
}

.cookies-settings-sub-link {
  position: relative;
  right: -45px;
  width: 83%;
  display: block;
}

body .cookies-settings .cookies-settings-container .cbtn2 {
  width: auto;
  margin: 0 auto;
}

.cookies-settings .close-btn {
  display: block;
  position: absolute;
  top: 8px;
  right: 18px;
  cursor: pointer;
}

.cookies-settings-list {
  position: relative;
  left: 50px;
  max-width: 350px;
}

.cookies-settings-link-acvite {
  display: block;
}

.cookies-settings-sub-list {
  display: none;
}

.cookies-settings-arrow {
  top: 3px;
  left: 0;
  cursor: pointer;
  position: absolute;
  height: 10px;
  width: 10px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(135deg);
}

.switch {
  position: relative;
  display: flex;
  width: 38px;
  height: 21px;
  margin: 0 10px;
  left: 34px;
  bottom: 20px;
}

.switch-checked {
  position: relative;
  display: flex;
  width: 38px;
  height: 21px;
  margin: 0 10px;
  left: 34px;
  bottom: 20px;
}

.small.interactive-button {
  display: flex;
}

.slider-btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9fa3a8;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-btn-checked {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2a197;
  transition: 0.4s;
  border-radius: 34px;
}

.switch input {
  display: none;
}

.switch-checked input {
  display: none;
}

.slider-btn::before {
  position: absolute;
  content: '';
  height: 17px;
  width: 17px;
  top: 2px;
  left: 2px;
  bottom: 5px;
  background-color: rgb(255, 255, 255);
  transition: 0.4s;
  border-radius: 50px;
}

.slider-btn-checked::before {
  position: absolute;
  content: '';
  height: 17px;
  width: 17px;
  top: 2px;
  left: 19px;
  bottom: 5px;
  background-color: rgb(255, 255, 255);
  transition: 0.4s;
  border-radius: 50px;
}

input:checked + .slider-btn {
  background-color: #f05c4b;
}

input:checked + .slider-btn::before {
  transform: translateX(17px);
}

.interactive-button.small .cbtn2 i {
  font-style: normal;
  font-size: 17px;
}

.interactive-button.small .cbtn1 i {
  font-style: normal;
  font-size: 17px;
}

.interactive-button.small .cbtn1 {
  background-color: transparent;
  color: black;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  min-width: 200px;
  height: 40px;
  border: transparent;
  cursor: pointer;
}

.interactive-button.small .cbtn2 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  min-width: 200px;
  border-radius: 30px;
  background-color: #f05c4b;
  border: transparent;
  height: 40px;
  cursor: pointer;
}

@media (max-width: 768px) {
  body .accept-cookies-text {
    display: block;
  }
}
@media (max-width: 899px) {
  .pn-container .pp-sub-list-text {
    left: 48px;
    width: calc(100% - 95px);
  }

  .cookies-settings-container {
    width: 400px;
  }

  .cookies-settings .close-btn {
    top: 5px;
  }

  .content .title__error {
    font-size: 35px;
    margin-top: 21px;
    margin-bottom: 21px;
  }

  .content .description__error {
    font-size: 13px;
    font-weight: 400;
  }

  .accept-cookies {
    min-height: auto;
  }

  .accept-cookies-text {
    display: block;
    position: relative;
    /* right: 50px; */
  }

  /* .accept-cookies .img-cookie{
      display: none;
      } */

  .accept-cookies p {
    font-size: 12px;
    padding: 0;
    position: relative;
  }

  .interactive-button.small .cbtn1 {
    right: 29px;
    min-width: 107px;
  }

  .interactive-button.small .cbtn2 {
    width: 100%;
    right: 30px;
  }

  .interactive-button.small .cbtn1 i {
    font-size: 17px;
    font-style: normal;
  }

  .interactive-button.small .cbtn2 i {
    font-size: 17px;
    font-style: normal;
  }

  .pn-container ul {
    margin-bottom: 15px;
  }

  .pn-container li {
    font-size: 15px;
  }

  .pn-container li ul li {
    font-size: 15px;
  }

  .pn-title {
    font-size: 20px;
  }

  .pn-text {
    font-size: 15px;
  }

  .pn-list-text {
    font-size: 15px;
  }
  .small.interactive-button {
    margin-top: 10px;
  }
  .btn-request__bckgrnd {
    width: 90%;
  }
}

@media (min-width: 550px) {
  .accept-cookies p {
    max-width: none;
  }
}
@media (max-width: 550px) {
  .accept-cookies {
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
  .accept-cookies img {
    display: none;
  }
  .accept-cookies > * + * {
    margin-left: 0;
  }
  .cookies-settings-container {
    width: 362px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .cookies-settings .close-btn {
    top: -7px;
    left: 326px;
  }
  .cookies-settings-sub-link {
    font-size: 13px;
  }
  .cookies-settings-container h1 {
    margin-left: 44px;
  }
  .cookies-settings {
    margin-top: 0;
    border-radius: 0;
  }
  .cookies-settings-canvas {
    top: 0px;
  }
}

.cookies-settings-pop-up hr {
  width: 83%;
  height: 1px;
  background: #aeaeae;
  color: #aeaeae;
  margin: 0;
  margin-left: 0px;
  border: none;
  margin-left: 45px;
  margin-top: 20px;
}

body .save-btn {
  margin-left: 50px;
}
