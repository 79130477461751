@import '../../../../variables.scss';

.menu {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 37px;
  min-width: 1152px;

  @include vp-1439 {
    position: fixed;
    align-items: flex-start;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100vw);
    background: #ffffff;
    transition: $trans-modal;
    width: 100%;
    height: 100%;
    z-index: 5;
    gap: 0;
    min-width: 100%;
  }

  @include vp-374 {
    padding: 0 22px;
  }

  &_open {
    transform: translateX(0);
  }

  &_isWhite {
    .menu_item a {
      color: #ffffff;

      &:hover {
        opacity: 0.8;
      }

      @include vp-1439 {
        color: #282d32;
      }
    }
  }

  &_closeBtn {
    display: none;

    @include vp-1439 {
      display: block;
      position: absolute;
      z-index: 10;
      top: 47px;
      right: 40px;
      color: #9b9b9b;
      border: none;
      background: none;
    }

    @include vp-767 {
      top: 52px;
      right: 42px;
    }

    @include vp-374 {
      top: 16px;
      right: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &_list {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 31px;
    margin: 0;

    @include vp-1439 {
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      padding-bottom: 10px;
      gap: 0;
    }

    @include vp-374 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &_item {
    padding: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    white-space: nowrap;

    a {
      color: #282d32;
      text-decoration: none;
      transition: $trans-default;

      &:hover {
        opacity: 0.6;
      }
    }

    @include vp-1439 {
      font-size: 40px;
      font-weight: 700;
      line-height: 130%;
    }

    @include vp-767 {
      font-size: 32px;
    }

    @include vp-374 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &_submitBtn {
    display: inline-flex;
    width: 218px;
    min-height: 45px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #3eafd4;
    border: none;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    transition: $trans-default;

    @include vp-1439 {
      width: 277px;
      min-height: 68px;
      padding: 20px;
      margin-left: 40px;
      font-size: 20px;
      font-weight: 600;
      line-height: 140%;
    }

    @include vp-767 {
      width: 293px;
      min-height: 62px;
      padding: 17px;
    }

    @include vp-374 {
      width: 100%;
      margin-left: 0;
    }

    &:hover {
      background: #2a95bc;
    }
  }
}

.burgerBtn {
  display: none;
  border: none;
  background: none;

  @include vp-1439 {
    display: block;
    padding: 0;
  }

  &_white {
    color: #ffffff;
  }
}
