@import '../../variables.scss';

.about {
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;

    @include vp-1023 {
      padding: 0 40px;
    }

    @include vp-767 {
      padding: 0 20px;
    }
  }

  &_title {
    margin: 0;
    margin-top: 40px;
    color: #282d32;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;
    text-align: center;

    b {
      color: #eb7d14;
    }

    @include vp-1023 {
      padding: 0 20px;
    }

    @include vp-767 {
      margin-top: 20px;
      font-size: 32px;
    }

    br {
      @include vp-1023 {
        display: none;
      }
    }
  }

  &_description {
    margin-top: 40px;
    color: #4c5157;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 0 110px;

    @include vp-1023 {
      padding: 0 30px;
    }

    @include vp-767 {
      padding: 0;
      margin-top: 20px;
      font-size: 20px;
    }
  }

  &_descriptionBottom {
    margin-top: 72px;
    padding: 0 105px;
    color: #4c5157;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    @include vp-1023 {
      padding: 0 40px;
    }

    @include vp-767 {
      padding: 0;
      font-size: 20px;
      margin-top: 50px;
    }
  }

  &_orderBtn {
    display: inline-flex;
    height: 51px;
    padding: 10px 40px;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    border: none;
    cursor: pointer;
    background: #eb7d14;
    transition: $trans-default;

    @include vp-767 {
      margin-top: 19px;
    }

    &:hover {
      background: #d66800;
    }
  }
}
