/* first style */
.line_style {
  fill: none;
  stroke: #68c5d7;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

.text {
  fill: #1a1a18;
}

.transparent {
  fill: #009ee3;
  opacity: 0.2;
}

.circle_1 {
  fill: url(#f029bd49-42a5-451a-8180-ed5960183037);
}

.number_style {
  fill: #fff;
}

.circle_2 {
  fill: url(#f029bd49-42a5-451a-8180-ed5960183037);
}

.circle_3 {
  fill: url(#f029bd49-42a5-451a-8180-ed5960183037);
}

.circle_4 {
  fill: url(#f029bd49-42a5-451a-8180-ed5960183037);
}

.circle_5 {
  fill: url(#f029bd49-42a5-451a-8180-ed5960183037);
}

.circle_6 {
  fill: url(#f029bd49-42a5-451a-8180-ed5960183037);
}

.circle_7 {
  fill: url(#f029bd49-42a5-451a-8180-ed5960183037);
}

/* second style */

.element_0 {
  stroke-dasharray: 409 411;
  stroke-dashoffset: 410;
}

.start .element_0 {
  animation: element_draw 1320ms ease-in-out 0ms forwards;
}

.element_1 {
  stroke-dasharray: 144 146;
  stroke-dashoffset: 145;
}

.start .element_1 {
  animation: element_draw 460ms ease-in-out 1320ms forwards;
}

.element_2 {
  stroke-dasharray: 180 182;
  stroke-dashoffset: 181;
}

.start .element_2 {
  animation: element_draw 580ms ease-in-out 1790ms forwards;
}

.element_3 {
  stroke-dasharray: 235 237;
  stroke-dashoffset: 236;
}

.start .element_3 {
  animation: element_draw 760ms ease-in-out 2380ms forwards;
}

.element_4 {
  stroke-dasharray: 146 148;
  stroke-dashoffset: 147;
}

.start .element_4 {
  animation: element_draw 470ms ease-in-out 3140ms forwards;
}

.element_5 {
  stroke-dasharray: 299 301;
  stroke-dashoffset: 300;
}

.start .element_5 {
  animation: element_draw 970ms ease-in-out 3620ms forwards;
}

.element_6 {
  stroke-dasharray: 124 126;
  stroke-dashoffset: 125;
}

.start .element_6 {
  animation: element_draw 400ms ease-in-out 4590ms forwards;
}

.element_7 {
  stroke-dasharray: 359 361;
  stroke-dashoffset: 360;
}

.start .element_7 {
  animation: element_draw 1160ms ease-in-out 4990ms forwards;
}

.element_8 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_8 {
  animation: element_draw 320ms ease-in-out 6150ms forwards;
}

.element_9 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_9 {
  animation: element_draw 320ms ease-in-out 6480ms forwards;
}

.element_10 {
  stroke-dasharray: 68 70;
  stroke-dashoffset: 69;
}

.start .element_10 {
  animation: element_draw 220ms ease-in-out 6800ms forwards;
}

.element_11 {
  stroke-dasharray: 84 86;
  stroke-dashoffset: 85;
}

.start .element_11 {
  animation: element_draw 270ms ease-in-out 7030ms forwards;
}

.element_12 {
  stroke-dasharray: 119 121;
  stroke-dashoffset: 120;
}

.start .element_12 {
  animation: element_draw 380ms ease-in-out 7300ms forwards;
}

.element_13 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_13 {
  animation: element_draw 320ms ease-in-out 7690ms forwards;
}

.element_14 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_14 {
  animation: element_draw 280ms ease-in-out 8010ms forwards;
}

.element_15 {
  stroke-dasharray: 79 81;
  stroke-dashoffset: 80;
}

.start .element_15 {
  animation: element_draw 250ms ease-in-out 8300ms forwards;
}

.element_16 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.start .element_16 {
  animation: element_draw 260ms ease-in-out 8560ms forwards;
}

.element_17 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_17 {
  animation: element_draw 280ms ease-in-out 8820ms forwards;
}

.element_18 {
  stroke-dasharray: 78 80;
  stroke-dashoffset: 79;
}

.start .element_18 {
  animation: element_draw 250ms ease-in-out 9110ms forwards;
}

.element_19 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
}

.start .element_19 {
  animation: element_draw 310ms ease-in-out 9360ms forwards;
}

.element_20 {
  stroke-dasharray: 72 74;
  stroke-dashoffset: 73;
}

.start .element_20 {
  animation: element_draw 230ms ease-in-out 9680ms forwards;
}

.element_21 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_21 {
  animation: element_draw 280ms ease-in-out 9920ms forwards;
}

.element_22 {
  stroke-dasharray: 79 81;
  stroke-dashoffset: 80;
}

.start .element_22 {
  animation: element_draw 250ms ease-in-out 10200ms forwards;
}

.element_23 {
  stroke-dasharray: 56 58;
  stroke-dashoffset: 57;
}

.start .element_23 {
  animation: element_draw 180ms ease-in-out 10460ms forwards;
}

.element_24 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_24 {
  animation: element_draw 280ms ease-in-out 10650ms forwards;
}

.element_25 {
  stroke-dasharray: 106 108;
  stroke-dashoffset: 107;
}

.start .element_25 {
  animation: element_draw 340ms ease-in-out 10930ms forwards;
}

.element_26 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_26 {
  animation: element_draw 280ms ease-in-out 11280ms forwards;
}

.element_27 {
  stroke-dasharray: 78 80;
  stroke-dashoffset: 79;
}

.start .element_27 {
  animation: element_draw 250ms ease-in-out 11570ms forwards;
}

.element_28 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.start .element_28 {
  animation: element_draw 260ms ease-in-out 11820ms forwards;
}

.element_29 {
  stroke-dasharray: 68 70;
  stroke-dashoffset: 69;
}

.start .element_29 {
  animation: element_draw 220ms ease-in-out 12090ms forwards;
}

.element_30 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_30 {
  animation: element_draw 280ms ease-in-out 12310ms forwards;
}

.element_31 {
  stroke-dasharray: 79 81;
  stroke-dashoffset: 80;
}

.start .element_31 {
  animation: element_draw 250ms ease-in-out 12600ms forwards;
}

.element_32 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.start .element_32 {
  animation: element_draw 260ms ease-in-out 12860ms forwards;
}

.element_33 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_33 {
  animation: element_draw 280ms ease-in-out 13130ms forwards;
}

.element_34 {
  stroke-dasharray: 74 76;
  stroke-dashoffset: 75;
}

.start .element_34 {
  animation: element_draw 240ms ease-in-out 13410ms forwards;
}

.element_35 {
  stroke-dasharray: 91 93;
  stroke-dashoffset: 92;
}

.start .element_35 {
  animation: element_draw 290ms ease-in-out 13660ms forwards;
}

.element_36 {
  stroke-dasharray: 113 115;
  stroke-dashoffset: 114;
}

.start .element_36 {
  animation: element_draw 360ms ease-in-out 13950ms forwards;
}

.element_37 {
  stroke-dasharray: 69 71;
  stroke-dashoffset: 70;
}

.start .element_37 {
  animation: element_draw 220ms ease-in-out 14320ms forwards;
}

.element_38 {
  stroke-dasharray: 84 86;
  stroke-dashoffset: 85;
}

.start .element_38 {
  animation: element_draw 270ms ease-in-out 14550ms forwards;
}

.element_39 {
  stroke-dasharray: 66 68;
  stroke-dashoffset: 67;
}

.start .element_39 {
  animation: element_draw 210ms ease-in-out 14830ms forwards;
}

.element_40 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_40 {
  animation: element_draw 280ms ease-in-out 15040ms forwards;
}

.element_41 {
  stroke-dasharray: 80 82;
  stroke-dashoffset: 81;
}

.start .element_41 {
  animation: element_draw 260ms ease-in-out 15330ms forwards;
}

.element_42 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.start .element_42 {
  animation: element_draw 260ms ease-in-out 15590ms forwards;
}

.element_43 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_43 {
  animation: element_draw 280ms ease-in-out 15860ms forwards;
}

.element_44 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_44 {
  animation: element_draw 320ms ease-in-out 16140ms forwards;
}

.element_45 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.start .element_45 {
  animation: element_draw 260ms ease-in-out 16470ms forwards;
}

.element_46 {
  stroke-dasharray: 69 71;
  stroke-dashoffset: 70;
}

.start .element_46 {
  animation: element_draw 220ms ease-in-out 16730ms forwards;
}

.element_47 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_47 {
  animation: element_draw 280ms ease-in-out 16960ms forwards;
}

.element_48 {
  stroke-dasharray: 109 111;
  stroke-dashoffset: 110;
}

.start .element_48 {
  animation: element_draw 350ms ease-in-out 17250ms forwards;
}

.element_49 {
  stroke-dasharray: 79 81;
  stroke-dashoffset: 80;
}

.start .element_49 {
  animation: element_draw 250ms ease-in-out 17600ms forwards;
}

.element_50 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_50 {
  animation: element_draw 280ms ease-in-out 17860ms forwards;
}

.element_51 {
  stroke-dasharray: 119 121;
  stroke-dashoffset: 120;
}

.start .element_51 {
  animation: element_draw 380ms ease-in-out 18150ms forwards;
}

.element_52 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
}

.start .element_52 {
  animation: element_draw 310ms ease-in-out 18540ms forwards;
}

.element_53 {
  stroke-dasharray: 72 74;
  stroke-dashoffset: 73;
}

.start .element_53 {
  animation: element_draw 230ms ease-in-out 18860ms forwards;
}

.element_54 {
  stroke-dasharray: 79 81;
  stroke-dashoffset: 80;
}

.start .element_54 {
  animation: element_draw 250ms ease-in-out 19090ms forwards;
}

.element_55 {
  stroke-dasharray: 56 58;
  stroke-dashoffset: 57;
}

.start .element_55 {
  animation: element_draw 180ms ease-in-out 19350ms forwards;
}

.element_56 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_56 {
  animation: element_draw 280ms ease-in-out 19540ms forwards;
}

.element_57 {
  stroke-dasharray: 79 81;
  stroke-dashoffset: 80;
}

.start .element_57 {
  animation: element_draw 250ms ease-in-out 19820ms forwards;
}

.element_58 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_58 {
  animation: element_draw 320ms ease-in-out 20080ms forwards;
}

.element_59 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
}

.start .element_59 {
  animation: element_draw 310ms ease-in-out 20400ms forwards;
}

.element_60 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_60 {
  animation: element_draw 280ms ease-in-out 20720ms forwards;
}

.element_61 {
  stroke-dasharray: 69 71;
  stroke-dashoffset: 70;
}

.start .element_61 {
  animation: element_draw 220ms ease-in-out 21010ms forwards;
}

.element_62 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
}

.start .element_62 {
  animation: element_draw 310ms ease-in-out 21230ms forwards;
}

.element_63 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_63 {
  animation: element_draw 280ms ease-in-out 21550ms forwards;
}

.element_64 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_64 {
  animation: element_draw 320ms ease-in-out 21840ms forwards;
}

.element_65 {
  stroke-dasharray: 72 74;
  stroke-dashoffset: 73;
}

.start .element_65 {
  animation: element_draw 230ms ease-in-out 22160ms forwards;
}

.element_66 {
  stroke-dasharray: 56 58;
  stroke-dashoffset: 57;
}

.start .element_66 {
  animation: element_draw 180ms ease-in-out 22400ms forwards;
}

.element_67 {
  stroke-dasharray: 78 80;
  stroke-dashoffset: 79;
}

.start .element_67 {
  animation: element_draw 250ms ease-in-out 22580ms forwards;
}

.element_68 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_68 {
  animation: element_draw 280ms ease-in-out 22840ms forwards;
}

.element_69 {
  stroke-dasharray: 109 111;
  stroke-dashoffset: 110;
}

.start .element_69 {
  animation: element_draw 350ms ease-in-out 23130ms forwards;
}

.element_70 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_70 {
  animation: element_draw 280ms ease-in-out 23480ms forwards;
}

.element_71 {
  stroke-dasharray: 78 80;
  stroke-dashoffset: 79;
}

.start .element_71 {
  animation: element_draw 250ms ease-in-out 23770ms forwards;
}

.element_72 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_72 {
  animation: element_draw 280ms ease-in-out 24020ms forwards;
}

.element_73 {
  stroke-dasharray: 79 81;
  stroke-dashoffset: 80;
}

.start .element_73 {
  animation: element_draw 250ms ease-in-out 24310ms forwards;
}

.element_74 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_74 {
  animation: element_draw 320ms ease-in-out 24570ms forwards;
}

.element_75 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
}

.start .element_75 {
  animation: element_draw 310ms ease-in-out 24890ms forwards;
}

.element_76 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_76 {
  animation: element_draw 280ms ease-in-out 25210ms forwards;
}

.element_77 {
  stroke-dasharray: 69 71;
  stroke-dashoffset: 70;
}

.start .element_77 {
  animation: element_draw 220ms ease-in-out 25490ms forwards;
}

.element_78 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
}

.start .element_78 {
  animation: element_draw 310ms ease-in-out 25720ms forwards;
}

.element_79 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_79 {
  animation: element_draw 280ms ease-in-out 26040ms forwards;
}

.element_80 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_80 {
  animation: element_draw 320ms ease-in-out 26330ms forwards;
}

.element_81 {
  stroke-dasharray: 72 74;
  stroke-dashoffset: 73;
}

.start .element_81 {
  animation: element_draw 230ms ease-in-out 26650ms forwards;
}

.element_82 {
  stroke-dasharray: 56 58;
  stroke-dashoffset: 57;
}

.start .element_82 {
  animation: element_draw 180ms ease-in-out 26890ms forwards;
}

.element_83 {
  stroke-dasharray: 78 80;
  stroke-dashoffset: 79;
}

.start .element_83 {
  animation: element_draw 250ms ease-in-out 27070ms forwards;
}

.element_84 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_84 {
  animation: element_draw 280ms ease-in-out 27330ms forwards;
}

.element_85 {
  stroke-dasharray: 74 76;
  stroke-dashoffset: 75;
}

.start .element_85 {
  animation: element_draw 240ms ease-in-out 27610ms forwards;
}

.element_86 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_86 {
  animation: element_draw 280ms ease-in-out 27860ms forwards;
}

.element_87 {
  stroke-dasharray: 68 70;
  stroke-dashoffset: 69;
}

.start .element_87 {
  animation: element_draw 220ms ease-in-out 28140ms forwards;
}

.element_88 {
  stroke-dasharray: 56 58;
  stroke-dashoffset: 57;
}

.start .element_88 {
  animation: element_draw 180ms ease-in-out 28360ms forwards;
}

.element_89 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.start .element_89 {
  animation: element_draw 260ms ease-in-out 28550ms forwards;
}

.element_90 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
}

.start .element_90 {
  animation: element_draw 310ms ease-in-out 28820ms forwards;
}

.element_91 {
  stroke-dasharray: 72 74;
  stroke-dashoffset: 73;
}

.start .element_91 {
  animation: element_draw 230ms ease-in-out 29130ms forwards;
}

.element_92 {
  stroke-dasharray: 83 85;
  stroke-dashoffset: 84;
}

.start .element_92 {
  animation: element_draw 270ms ease-in-out 29370ms forwards;
}

.element_93 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_93 {
  animation: element_draw 280ms ease-in-out 29640ms forwards;
}

.element_94 {
  stroke-dasharray: 79 81;
  stroke-dashoffset: 80;
}

.start .element_94 {
  animation: element_draw 250ms ease-in-out 29930ms forwards;
}

.element_95 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.start .element_95 {
  animation: element_draw 260ms ease-in-out 30190ms forwards;
}

.element_96 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_96 {
  animation: element_draw 280ms ease-in-out 30460ms forwards;
}

.element_97 {
  stroke-dasharray: 89 91;
  stroke-dashoffset: 90;
}

.start .element_97 {
  animation: element_draw 290ms ease-in-out 30740ms forwards;
}

.element_98 {
  stroke-dasharray: 99 101;
  stroke-dashoffset: 100;
}

.start .element_98 {
  animation: element_draw 320ms ease-in-out 31030ms forwards;
}

.element_99 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_99 {
  animation: element_draw 280ms ease-in-out 31360ms forwards;
}

.element_100 {
  stroke-dasharray: 66 68;
  stroke-dashoffset: 67;
}

.start .element_100 {
  animation: element_draw 210ms ease-in-out 31640ms forwards;
}

.element_101 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_101 {
  animation: element_draw 280ms ease-in-out 31860ms forwards;
}

.element_102 {
  stroke-dasharray: 78 80;
  stroke-dashoffset: 79;
}

.start .element_102 {
  animation: element_draw 250ms ease-in-out 32150ms forwards;
}

.element_103 {
  stroke-dasharray: 97 99;
  stroke-dashoffset: 98;
}

.start .element_103 {
  animation: element_draw 310ms ease-in-out 32410ms forwards;
}

.element_104 {
  stroke-dasharray: 72 74;
  stroke-dashoffset: 73;
}

.start .element_104 {
  animation: element_draw 230ms ease-in-out 32720ms forwards;
}

.element_105 {
  stroke-dasharray: 87 89;
  stroke-dashoffset: 88;
}

.start .element_105 {
  animation: element_draw 280ms ease-in-out 32960ms forwards;
}

.element_106 {
  stroke-dasharray: 78 80;
  stroke-dashoffset: 79;
}

.start .element_106 {
  animation: element_draw 250ms ease-in-out 33240ms forwards;
}

.element_107 {
  stroke-dasharray: 56 58;
  stroke-dashoffset: 57;
}

.start .element_107 {
  animation: element_draw 180ms ease-in-out 33500ms forwards;
}

.element_108 {
  stroke-dasharray: 88 90;
  stroke-dashoffset: 89;
}

.start .element_108 {
  animation: element_draw 280ms ease-in-out 33680ms forwards;
}

.element_109 {
  stroke-dasharray: 308 310;
  stroke-dashoffset: 309;
}

.start .element_109 {
  animation: element_draw 990ms ease-in-out 33970ms forwards;
}

.element_110 {
  stroke-dasharray: 256 258;
  stroke-dashoffset: 257;
}

.start .element_110 {
  animation: element_draw 830ms ease-in-out 34970ms forwards;
}

.element_111 {
  stroke-dasharray: 113 115;
  stroke-dashoffset: 114;
}

.start .element_111 {
  animation: element_draw 360ms ease-in-out 35800ms forwards;
}

.element_112 {
  stroke-dasharray: 308 310;
  stroke-dashoffset: 309;
}

.start .element_112 {
  animation: element_draw 990ms ease-in-out 36170ms forwards;
}

.element_113 {
  stroke-dasharray: 256 258;
  stroke-dashoffset: 257;
}

.start .element_113 {
  animation: element_draw 830ms ease-in-out 37170ms forwards;
}

.element_114 {
  stroke-dasharray: 138 140;
  stroke-dashoffset: 139;
}

.start .element_114 {
  animation: element_draw 440ms ease-in-out 38000ms forwards;
}

.element_115 {
  stroke-dasharray: 308 310;
  stroke-dashoffset: 309;
}

.start .element_115 {
  animation: element_draw 990ms ease-in-out 38450ms forwards;
}

.element_116 {
  stroke-dasharray: 256 258;
  stroke-dashoffset: 257;
}

.start .element_116 {
  animation: element_draw 830ms ease-in-out 39450ms forwards;
}

.element_117 {
  stroke-dasharray: 152 154;
  stroke-dashoffset: 153;
}

.start .element_117 {
  animation: element_draw 490ms ease-in-out 40280ms forwards;
}

.element_118 {
  stroke-dasharray: 308 310;
  stroke-dashoffset: 309;
}

.start .element_118 {
  animation: element_draw 990ms ease-in-out 40780ms forwards;
}

.element_119 {
  stroke-dasharray: 256 258;
  stroke-dashoffset: 257;
}

.start .element_119 {
  animation: element_draw 830ms ease-in-out 41780ms forwards;
}

.element_120 {
  stroke-dasharray: 133 135;
  stroke-dashoffset: 134;
}

.start .element_120 {
  animation: element_draw 430ms ease-in-out 42610ms forwards;
}

.element_121 {
  stroke-dasharray: 308 310;
  stroke-dashoffset: 309;
}

.start .element_121 {
  animation: element_draw 990ms ease-in-out 43040ms forwards;
}

.element_122 {
  stroke-dasharray: 256 258;
  stroke-dashoffset: 257;
}

.start .element_122 {
  animation: element_draw 830ms ease-in-out 44040ms forwards;
}

.element_123 {
  stroke-dasharray: 158 160;
  stroke-dashoffset: 159;
}

.start .element_123 {
  animation: element_draw 510ms ease-in-out 44870ms forwards;
}

.element_124 {
  stroke-dasharray: 308 310;
  stroke-dashoffset: 309;
}

.start .element_124 {
  animation: element_draw 990ms ease-in-out 45390ms forwards;
}

.element_125 {
  stroke-dasharray: 256 258;
  stroke-dashoffset: 257;
}

.start .element_125 {
  animation: element_draw 830ms ease-in-out 46390ms forwards;
}

.element_126 {
  stroke-dasharray: 165 167;
  stroke-dashoffset: 166;
}

.start .element_126 {
  animation: element_draw 530ms ease-in-out 47220ms forwards;
}

.element_127 {
  stroke-dasharray: 308 310;
  stroke-dashoffset: 309;
}

.start .element_127 {
  animation: element_draw 990ms ease-in-out 47760ms forwards;
}

.element_128 {
  stroke-dasharray: 256 258;
  stroke-dashoffset: 257;
}

.start .element_128 {
  animation: element_draw 830ms ease-in-out 48760ms forwards;
}

.element_129 {
  stroke-dasharray: 125 127;
  stroke-dashoffset: 126;
}

.start .element_129 {
  animation: element_draw 400ms ease-in-out 49590ms forwards;
}

@keyframes element_draw {
  0% {
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes element_fade {
  0% {
    stroke-opacity: 1;
  }

  95.65217391304348% {
    stroke-opacity: 1;
  }

  100% {
    stroke-opacity: 0;
  }
}
