@import '../../variables.scss';

.technology {
  width: 100%;
  max-width: 1300px;
  padding: 20px 20px 0 20px;
  margin: 0 auto;

  @include vp-1439 {
    max-width: 624px;
    padding-left: 0;
    padding-right: 0;
  }

  @include vp-767 {
    max-width: 343px;
    padding-top: 17px;
  }

  &_title {
    margin: 0;
    text-align: center;
    color: #282d32;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    @include vp-767 {
      font-size: 40px;
    }
  }

  &_description {
    margin: 0;
    margin-top: 18px;
    color: #4c5157;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 0 20px;

    @include vp-1439 {
      padding: 0;
    }

    @include vp-767 {
      font-size: 20px;
      margin-top: 15px;
    }

    @include vp-374 {
      padding: 0 16px;
    }
  }
}
