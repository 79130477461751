@import '../../variables';

.orderForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  height: auto;

  @include vp-1023 {
    padding: 40px 70px;
    min-height: 950px;
  }

  @include vp-767 {
    padding: 15px 16px;
    min-height: 812px;
  }

  &_termOfUse {
    color: #9b9b9b;
  }

  &ForContactFrame {
    padding: 40px 0;
  }

  &_title {
    margin: 0;
    color: #282d32;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    @include vp-1023 {
      margin-top: 100px;
      align-self: flex-start;
    }

    @include vp-767 {
      font-size: 40px;
      margin-top: 45px;
    }
  }

  &_description {
    max-width: 683px;
    margin-top: 20px;
    margin-bottom: 38px;
    color: #9b9b9b;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    @include vp-1023 {
      align-self: flex-start;
      max-width: 500px;
      text-align: left;
      padding-right: 20px;
    }

    @include vp-767 {
      font-size: 14px;
      font-weight: 400;
    }

    &Orange {
      color: #2a95bc;
      max-width: 544px;
    }
  }

  input,
  textarea {
    width: 100%;
    height: 65px;
    padding: 20px 10px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 2px solid #9b9b9b;
    outline: none;
    color: #000;
    font-family: 'Petrov Sans-Trial';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    transition: $trans-default;

    @include vp-767 {
      margin-bottom: 30px;
    }

    &::-webkit-input-placeholder {
      color: #9b9b9b;
      font-family: 'Petrov Sans-Trial';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;

      @include vp-767 {
        font-size: 14px;
      }
    }

    &::-moz-placeholder {
      color: #9b9b9b;
      font-family: 'Petrov Sans-Trial';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;

      @include vp-767 {
        font-size: 14px;
      }
    } /* Firefox 19+ */

    &:-moz-placeholder {
      color: #9b9b9b;
      font-family: 'Petrov Sans-Trial';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;

      @include vp-767 {
        font-size: 14px;
      }
    } /* Firefox 18- */

    &:-ms-input-placeholder {
      color: #9b9b9b;
      font-family: 'Petrov Sans-Trial';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;

      @include vp-767 {
        font-size: 14px;
      }
    }

    &:focus,
    &:hover {
      border-bottom: 2px solid #282d32;
    }
  }

  .aboutInput {
    resize: none;
    height: 120px;
    border-bottom: 2px solid #9b9b9b;

    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    scrollbar-width: 0;
    transition: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &_agreement {
    margin-top: 20px;
    color: #9b9b9b;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-align: center;
    padding: 0 45px;

    @include vp-1023 {
      text-align: left;
      align-self: flex-start;
      padding: 0;
    }

    @include vp-767 {
      font-size: 14px;
      margin-top: 0;
    }
  }

  &_submitBtn {
    margin-top: 46px;
    display: flex;
    width: 420px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background: #3eafd4;
    transition: $trans-default;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    &:hover {
      background: #2a95bc;
    }

    @include vp-1023 {
      width: 385px;
      align-self: flex-start;
    }

    @include vp-767 {
      margin-top: 40px;
      width: 100%;
      max-width: 343px;
      font-size: 14px;
    }
  }
}
