.accept_cookies {
  position: fixed;
  z-index: 900;
  background-color: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  transition:
    opacity 0.35s,
    visibility 0.35s;
  transition-delay: 0s;
  transition-timing-function: ease;
  font-size: 18px;
  color: #404040;
  font-weight: 400;
  left: 1%;
  right: 1%;
  width: auto;
  border-radius: 20px;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 6.25rem;
  padding: 1.25rem 2rem;
}

.accept_cookies > * + * {
  margin-left: 2.5rem;
}

.show {
  visibility: visible;
}

.accept_cookies_text {
  display: flex;
  width: 1745px;
  justify-content: space-between;
}

.accept_cookies img {
  width: 65px;
  height: 65px;
  margin-bottom: auto;
}

.accept_cookies p {
  padding-left: 2.5rem;
  font-size: 12px;
  line-height: 18px;
}

.close_btn {
  width: 35px;
  height: 35px;
  right: 20px;
  position: relative;
  cursor: pointer;
}
.accept_cookies a {
  color: #404040;
  text-decoration: underline;
  cursor: pointer;
}

.accept_cookies a.hover,
.accept_cookies__text a:hover {
  text-decoration: underline;
}

.accept_cookies.hide {
  opacity: 0;
  display: none;
}

.accept_cookies.hidden {
  display: none;
}

.cookies_settings_btn {
  margin-top: 25px;
}

.cookies_settings_canvas {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: -90px;
  z-index: 1000;
  width: 100%;
  height: 120%;
  backdrop-filter: blur(3px);
}

.cookies_settings_list #coockiesS1 .cookies_settings_link {
  cursor: auto;
}

.cookies_settings_list #coockiesS1 .cookies_settings_arrow {
  cursor: auto;
}

.cookies_settings {
  display: table;
  margin: auto;
  margin-top: auto;
  position: sticky;
  bottom: 400px;
  z-index: 9999;
  background-color: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 24px;
  color: #4c5157;
  font-weight: 400;
  left: 1%;
  border-radius: 18px;
  padding: 1.25rem 2rem;
  width: 618px;
  height: 300px;
  margin-top: 100px;
}

.cookies_settings_container {
  position: relative;
  right: 28px;
  height: 600px;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.cookies_settings_header {
  text-align: left;
  font-weight: 500;
  font-size: 32px;
  line-height: 38.4px;
  color: #282d32;
  margin-left: 45px;
  margin-top: 20px;
  width: 493px;
  position: relative;
}

.cookies_settings_container p {
  font-size: 18px;
  max-width: 420px;
  margin-left: 91px;
  margin-top: 16px;
  top: 20px;
  position: relative;
  color: #282d32;
}

.cookies_settings_link {
  font-weight: 600;
}

.cookies_settings_sub_link {
  position: relative;
  right: -45px;
  width: 83%;
  display: block;
}

.cbtn2 {
  width: auto;
  margin: 0 auto;
}
.cbtn2 i {
  font-style: normal;
  font-size: 17px;
}

.cookies_settings .close_btn {
  display: block;
  position: absolute;
  top: 8px;
  right: 18px;
  cursor: pointer;
}

.cookies_settings_list {
  position: relative;
  left: 50px;
  max-width: 350px;
}

.cookies_settings_link_acvite {
  display: block;
}

.cookies_settings_sub_list {
  display: none;
}

.cookies_settings_arrow {
  top: 3px;
  left: 0;
  cursor: pointer;
  position: absolute;
  height: 10px;
  width: 10px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(135deg);
}

.switch {
  position: relative;
  display: flex;
  width: 38px;
  height: 21px;
  margin: 0 10px;
  left: 34px;
  bottom: 20px;
}

.switch_checked {
  position: relative;
  display: flex;
  width: 38px;
  height: 21px;
  margin: 0 10px;
  left: 34px;
  bottom: 20px;
}

.interactive_button {
  display: flex;
}

.slider_btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9fa3a8;
  transition: 0.4s;
  border-radius: 34px;
}

.slider_btn_checked {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a6e3f8;
  transition: 0.4s;
  border-radius: 34px;
}

.switch input {
  display: none;
}

.switch_checked input {
  display: none;
}

.slider_btn::before {
  position: absolute;
  content: '';
  height: 17px;
  width: 17px;
  top: 2px;
  left: 2px;
  bottom: 5px;
  background-color: rgb(255, 255, 255);
  transition: 0.4s;
  border-radius: 50px;
}

.slider_btn_checked::before {
  position: absolute;
  content: '';
  height: 17px;
  width: 17px;
  top: 2px;
  left: 19px;
  bottom: 5px;
  background-color: rgb(255, 255, 255);
  transition: 0.4s;
  border-radius: 50px;
}

input:checked + .slider_btn {
  background-color: #3eafd4;
}

input:checked + .slider_btn::before {
  transform: translateX(17px);
}

.cbtn2 i {
  font-style: normal;
  font-size: 17px;
}

.cbtn1 i {
  font-style: normal;
  font-size: 17px;
}

.cbtn1 {
  background-color: transparent;
  color: black;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  min-width: 200px;
  height: 40px;
  border: transparent;
  cursor: pointer;
}

.cbtn2 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  min-width: 200px;
  border-radius: 30px;
  background-color: #3eafd4;
  border: transparent;
  height: 40px;
  cursor: pointer;
}

.cbtn2:hover {
  background-color: #2a95bc;
}

@media (max-width: 768px) {
  .accept_cookies_text {
    display: block;
  }
}
@media (max-width: 899px) {
  .pp_sub_list_text {
    left: 48px;
    width: calc(100% - 95px);
  }

  .cookies_settings_container {
    width: 570px;
  }

  .close_btn {
    top: 5px;
  }

  .title__error {
    font-size: 35px;
    margin-top: 21px;
    margin-bottom: 21px;
  }

  .description__error {
    font-size: 13px;
    font-weight: 400;
  }

  .accept_cookies {
    min-height: auto;
    width: auto !important;
  }

  .accept_cookies_text {
    display: block;
    position: relative;
    /* right: 50px; */
  }

  /* .accept_cookies .img_cookie{
      display: none;
      } */

  .accept_cookies p {
    font-size: 12px;
    padding: 0;
    position: relative;
  }

  .cbtn1 {
    right: 29px;
    min-width: 107px;
  }

  .cbtn2 {
    width: 100%;
    right: 30px;
  }

  .cbtn1 i {
    font-size: 17px;
    font-style: normal;
  }

  .cbtn2 i {
    font-size: 17px;
    font-style: normal;
  }

  .pn_container ul {
    margin-bottom: 15px;
  }

  .pn_container li {
    font-size: 15px;
  }

  .pn_container li ul li {
    font-size: 15px;
  }

  .pn_title {
    font-size: 20px;
  }

  .pn_text {
    font-size: 15px;
  }

  .pn_list_text {
    font-size: 15px;
  }
  .small.interactive_button {
    margin-top: 10px;
  }
  .btn_request__bckgrnd {
    width: 90%;
  }
}

@media (min-width: 550px) {
  .accept_cookies p {
    max-width: none;
  }
}
@media (max-width: 550px) {
  .accept_cookies {
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
  .accept_cookies img {
    display: none;
  }
  .accept_cookies > * + * {
    margin-left: 0;
  }
  .cookies_settings_container {
    width: 362px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .close_btn {
    top: -7px;
    left: 326px;
  }
  .cookies_settings_sub_link {
    font-size: 13px;
  }
  .cookies_settings_container h1 {
    margin-left: 44px;
  }
  .cookies_settings {
    margin-top: 0;
    border-radius: 0;
  }
  .cookies_settings_canvas {
    top: 0px;
  }
}

.cookies_settings_pop_up hr {
  width: 83%;
  height: 1px;
  background: #aeaeae;
  color: #aeaeae;
  margin: 0;
  margin-left: 0px;
  border: none;
  margin-left: 45px;
  margin-top: 20px;
}

.save_btn {
  margin-left: 50px;
}

interactiveButton {
  display: flex;
  align-items: center;
}
