@import '../../variables.scss';

.contacts {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  padding: 40px 0 0 0;
  margin: 0 auto;

  @include vp-767 {
    padding-top: 0;
  }

  &_bg {
    position: absolute;
    z-index: 1;
    top: 68px;
    left: 50%;
    transform: translateX(-50%);
    width: 518px;
    height: auto;

    @include vp-1439 {
      top: 28px;
    }

    @include vp-1023 {
      top: 48px;
      width: 623px;
      height: 622px;
    }

    @include vp-767 {
      top: 112px;
      width: 374px;
      height: 374px;
    }
  }

  &_title {
    margin-top: 57px;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
    color: #282d32;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 62.4px */
    text-transform: uppercase;
    text-align: center;

    @include vp-1023 {
      margin-top: 50px;
    }

    @include vp-767 {
      margin-top: 10px;
      font-size: 32px;
    }
  }

  &_filed {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
  }

  &_label {
    margin-top: 10px;
    margin-bottom: 0;
    color: #9b9b9b;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    @include vp-767 {
      font-size: 14px;
    }
  }

  &_value {
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 380px;
    color: #282d32;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration: none;
    text-align: center;

    @include vp-767 {
      font-size: 20px;
    }
  }

  &_phoneContainer {
    display: flex;
    gap: 10px;

    @include vp-767 {
      flex-direction: column;
    }
  }

  &_copyright {
    margin-top: 48px;
    color: #282d32;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @include vp-1023 {
      margin-top: 124px;
    }

    @include vp-767 {
      margin-top: 30px;
      text-align: center;
      font-size: 14px;
    }
  }

  &_tgImage {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

  &_frame {
    display: grid;
    grid-template-columns: 520px 1fr;
    column-gap: 30px;
    width: 100%;
    padding: 20px;
    min-height: 690px;
    background: #fff;
    box-shadow:
      0px 4px 4px -2px rgba(24, 39, 75, 0.08),
      0px 2px 4px -2px rgba(24, 39, 75, 0.12);

    @include vp-1023 {
      box-shadow: none;
      background: #fafafa;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    @include vp-767 {
      min-height: 567px;
    }
  }

  &_frameLeft {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 70px;
    width: 520px;
    background: #fafafa;

    @include vp-1023 {
      background: none;
      width: 100%;
      margin-right: 0;
    }
  }

  &_formWrapper {
    @include vp-1023 {
      display: none;
    }
  }

  &_orderBtn {
    display: none;

    @include vp-1023 {
      margin-top: 0;
      display: flex;
      width: 462px;
      padding: 20px;
      justify-content: center;
      align-items: center;
      background: #eb7d14;
      transition: $trans-default;
      border: none;
      cursor: pointer;
      color: #ffffff;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;

      &:hover {
        background: #d66800;
      }

      @include vp-767 {
        margin-top: 20px;
        width: 100%;
        max-width: 343px;
        font-size: 18px;
      }

      @include vp-374 {
        width: 90%;
      }
    }
  }
}
