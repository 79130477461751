@import '../../../../variables.scss';

.statistic {
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 110px;
  margin-top: 70px;

  @include vp-1023 {
    padding-left: 32px;
    padding-right: 5px;
    margin-top: 51px;
    gap: 90px;
  }

  @include vp-767 {
    margin-top: 37px;
    padding-left: 10px;
    flex-direction: column-reverse;
    justify-content: initial;
    align-items: flex-start;
    gap: 0;
  }

  &_left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 40px;

    @include vp-1023 {
      grid-template-columns: 250px;
    }

    @include vp-767 {
      width: 100%;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }

  &_cell {
    display: flex;
    flex-direction: column;
  }

  &_headerCell {
    grid-column: 1 / -1;
  }

  &_cellTitle {
    margin: 0;
    color: #282d32;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    @include vp-767 {
      font-size: 40px;
    }
  }

  &_cellDescription {
    margin: 0;
    color: #9b9b9b;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 225px;

    @include vp-767 {
      font-size: 13px;
      max-width: 100%;
    }
  }

  &_right {
    position: relative;
  }

  &_title {
    position: relative;
    z-index: 2;
    color: #282d32;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;
    max-width: 457px;

    @include vp-767 {
      width: 100%;
      font-size: 32px;
    }
  }

  &_bg {
    position: absolute;
    top: -153px;
    left: -46px;

    @include vp-1023 {
      top: -135px;
      left: -139px;
    }

    @include vp-767 {
      top: -83px;
      left: 22px;
      width: 272px;
      height: 272px;
    }
  }
}
