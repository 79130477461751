@import '../../variables.scss';

.vacancy {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding-bottom: 20px;

  @include vp-1439 {
    max-width: 800px;
    padding-bottom: 0;
  }

  @include vp-1023 {
    max-width: 620px;
  }

  @include vp-767 {
    padding: 0 16px;
  }

  &_title {
    margin: 0;
    margin-top: 20px;
    color: #282d32;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    @include vp-767 {
      margin-top: 20px;
      color: #282d32;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      text-transform: uppercase;
    }
  }

  &_description {
    margin-top: 20px;
    margin-bottom: 40px;
    color: #282d32;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    @include vp-767 {
      margin-top: 20px;
      margin-bottom: 40px;
      color: #282d32;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
}
