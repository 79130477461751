@import '../../variables.scss';

.header {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 10px 70px;

  @include vp-1439 {
    width: 100%;
    padding: 8px 68px;
    justify-content: space-between;
  }

  @include vp-767 {
    padding: 7px 11px;
  }

  &_logo {
    margin-right: 24px;
    transition: $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        opacity: 0.6;
      }
    }

    @include vp-1439 {
      margin-left: 13px;
    }
  }
}

.headerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  border-bottom: 1px solid #e3e4e6;
  background: #fafafa;
}

.headerPadding {
  width: 100%;
  height: 66px;

  @include vp-1439 {
    height: 60px;
  }
}
