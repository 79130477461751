@import '../../variables.scss';

.main {
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  min-height: 685px;
  padding-top: 75px;
  width: 100%;

  @media (max-width: 2200px) {
    position: initial;
    transform: none;
    top: initial;
  }

  @include vp-1439 {
    padding-top: 70px;
    min-height: 950px;
  }

  @include vp-767 {
    padding-top: 26px;
    min-height: 650px;
  }

  &_container {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
  }

  &_bg {
    position: absolute;
    top: -141px;
    right: 56px;

    @include vp-1439 {
      top: 28px;
      right: 68px;
    }

    @include vp-767 {
      top: -5px;
      right: -33px;
      width: 438px;
      height: auto;
    }
  }

  .swiper {
    padding-bottom: 65px;

    @include vp-1439 {
      padding-bottom: 75px;
    }

    @include vp-767 {
      padding-bottom: 55px;
    }

    :global(.swiper-pagination-bullets) {
      left: -14px;
    }

    :global(.swiper-pagination-bullet) {
      position: relative;
      width: 55px;
      height: 25px;
      color: #9b9b9b;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      background: transparent;
      opacity: 1;
      transition: $trans-default;
      margin: 0 10px;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        content: '';
        width: 8px;
        height: 8px;
        background: #9b9b9b;
        border-radius: 50%;
        transition: $trans-default;
      }
    }

    :global(.swiper-pagination-bullet-active) {
      color: #3eafd4;

      &::after {
        width: 16px;
        height: 16px;
        background: #3eafd4;
      }
    }
  }
}
