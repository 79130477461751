@import '../../variables.scss';

.footerWrapper {
  border-top: 1px solid#e3e4e6;
  background: #fafafa;
  margin-top: 40px;
  flex: 0 0 auto;
}

.footer {
  display: grid;
  grid-template-columns: 1fr repeat(5, minmax(140px, max-content));
  column-gap: 31px;
  grid-template-rows: auto auto;
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 18px 76px 0 76px;

  @include vp-1439 {
    width: 100%;
    padding: 13px 79px;
    column-gap: 14px;
  }

  @include vp-767 {
    padding: 10px 16px;
    grid-template-columns: 1fr 140px;
    grid-template-rows: auto auto auto auto;
    row-gap: 5px;
  }

  @include vp-374 {
    grid-template-columns: 1fr;
    row-gap: 14px;
    justify-items: start;
  }

  &_toMainBtn {
    display: inline-flex;
  }

  &_logo {
    margin-right: 50px;

    @include vp-1439 {
      margin-right: 10px;
    }

    @include vp-767 {
      margin-left: 5px;
      margin-top: 8px;
    }
  }

  &_contact {
    color: #282d32;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    justify-self: end;

    @include vp-767 {
      font-size: 14px;
    }

    @include vp-374 {
      justify-self: start;
    }
  }

  &_contactPhone {
    @include vp-767 {
      grid-column: 1/ -1;
    }
  }

  &_copyright {
    grid-column: 1/-1;
    justify-self: end;
    color: #282d32;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @include vp-767 {
      font-size: 14px;
      margin: 0;
    }

    @include vp-374 {
      justify-self: start;
    }
  }

  &_address {
    grid-column: 1/-1;
    justify-self: start;
    color: #282d32;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0 !important;
    margin-top: 5px !important;
    font-size: 12px !important;

    @include vp-767 {
      font-size: 14px;
      margin: 0;
    }

    @include vp-374 {
      justify-self: start;
    }

    a {
      text-decoration: none;
      color: #282d32;
    }
  }

  &_cont {
    grid-column: 1/-1;
    justify-self: start;
    margin-top: 20px !important;
  }
}
