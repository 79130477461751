@import '../../variables.scss';

.projectButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin-top: 45px;

  @include vp-767 {
    margin-top: 27px;
    gap: 143px;
  }

  &_item {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #2a95bc;
    text-decoration: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    cursor: pointer;

    &_disabled {
      color: #9b9b9b;
      cursor: default;
      pointer-events: none;
    }
  }

  &_iconLeft {
    display: flex;
    align-items: center;

    svg {
      transform: rotate(180deg);
    }
  }

  &_iconRight {
    display: flex;
    align-items: center;
  }

  span {
    @include vp-767 {
      display: none;
    }
  }
}
